<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" >
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Client Tracking <span>Rename</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmit" v-slot="{ errors }" ref="rename-progress-tracking-form">
                            <div class="group_item">
                                <label class="input_label">Client Tracking</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Client Tracking 1" rules="required" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Rename Progress Tracking',

        data () {
            return {
                form: {
                    name: '',
                    id: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
            progressTracking: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        name: vm.progressTracking.name,
                        id: vm.progressTracking.id,
                    }
                }
            }
        },

        computed: mapState({
            loader: state => state.progressTrackingModule.progressTrackingComponentLoader,
        }),

        methods: {
            ...mapActions({
                renameProgressTracking: 'progressTrackingModule/renameProgressTracking'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['rename-progress-tracking-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameProgressTracking(form);
                    }
                });
            },

            handleRenameProgressTracking (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.renameProgressTracking(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.refreshProgressTracking) {
                            vm.$parent.$parent.refreshProgressTracking();
                        } else {
                            vm.$parent.refreshProgressTracking();
                        }

                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
